import { FC, memo, ReactElement } from "react"

const ResendCode: FC = memo((): ReactElement => {
  const resendCode = () => {}

  return (
    <p className="text-sm">
      Didn’t receive the code?{" "}
      <span className="cursor-pointer font-bold font-body" onClick={resendCode}>
        Resend it
      </span>
    </p>
  )
})

export default ResendCode
