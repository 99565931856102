import { ChangeEvent, FormEvent, useEffect, useState } from "react"
import { useGenerateWellnessCodeMutation } from "app/services"
import { WellnessCodeType } from "constants/authConstants"
import { string } from "yup"
import {
  EMAIL_REGEX_WITH_CODE,
  PHONE_REGEX_WITH_CODE,
  PHONE_RULES,
} from "constants/validationConstants"

interface Props {
  setUserId: (id: string) => void
  setContact: (id: string) => void
}

const useSendWellnessCode = ({ setUserId, setContact }: Props) => {
  const [value, setValue] = useState("")
  const [phoneEmailValue, setPhoneEmailValue] = useState("")
  const [error, setError] = useState<string>()
  const [sendCode, { isLoading, data }] = useGenerateWellnessCodeMutation()

  useEffect(() => {
    if (data && data?.id) {
      if (setUserId) {
        setUserId(String(data.id))
      }
      if (setContact) {
        setContact(value)
      }
    }
  }, [data])

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()

    if (isLoading) {
      return
    }

    let type = null
    if (value.match(/@/)) {
      if (!string().email().isValidSync(value)) {
        setError("This email address isn't valid. Please check and try again.")
        return
      }
      type = WellnessCodeType.EMAIL
    } else {
      if (!PHONE_RULES.isValidSync(value)) {
        setError("This phone number isn't valid. Please check and try again.")
        return
      }
      type = WellnessCodeType.PHONE
    }
    setError(undefined)
    sendCode({ type, value })
  }

  const handlePhoneValueSubmit = (event: FormEvent) => {
    event.preventDefault()

    if (isLoading) {
      return
    }

    const type: WellnessCodeType = detectInputType(phoneEmailValue)

    if (type === WellnessCodeType.EMAIL) {
      if (!EMAIL_REGEX_WITH_CODE.test(phoneEmailValue)) {
        setError("This email address isn't valid. Please check and try again.")
        return
      }
    }

    if (type === WellnessCodeType.PHONE) {
      if (!PHONE_REGEX_WITH_CODE.test(phoneEmailValue)) {
        setError("This phone number isn't valid. Please check and try again.")
        return
      }
    }

    setError(undefined)
    sendCode({ type, value: phoneEmailValue })
  }

  const handleInputChange = (e: ChangeEvent<any>) => {
    setValue(e.target.value)
  }

  const formatPhoneNumber = (value: string): string => {
    const phoneNumber = value.replace(/[^\d]/g, "")
    if (phoneNumber.length <= 3) {
      return phoneNumber
    }

    if (phoneNumber.length >= 4 && phoneNumber.length <= 6) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }

    if (phoneNumber.length > 6) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
    }

    return phoneNumber
  }

  const deformatInvalidPhoneNumber = (value: string) => {
    return value.replace(
      /^\((.{3})\) (.{1,3})(-(.{1,}))?$/,
      (match, p1, p2, p3, p4) => {
        return p1 + (p2 || "") + (p4 || "")
      },
    )
  }

  const detectInputType = (value: string): WellnessCodeType => {
    const rawValue = value.replace(/[)( -]/g, "")
    const isNumeric = /^\d+$/.test(rawValue)

    if (isNumeric) {
      return WellnessCodeType.PHONE
    } else {
      return WellnessCodeType.EMAIL
    }
  }
  const handlePhoneEmailInputChange = (e: ChangeEvent<any>): void => {
    const value = e.target.value
    const inputType = detectInputType(value)

    if (inputType === WellnessCodeType.PHONE) {
      const formattedPhone = formatPhoneNumber(value)
      setPhoneEmailValue(formattedPhone)
    } else {
      const formattedEmail = deformatInvalidPhoneNumber(value)
      setPhoneEmailValue(formattedEmail)
    }
  }

  return {
    handleInputChange,
    handlePhoneEmailInputChange,
    handleSubmit,
    handlePhoneValueSubmit,
    phoneEmailValue,
    isButtonDisabled: !Boolean(value) && !Boolean(phoneEmailValue),
    isLoading,
    error,
  }
}

export default useSendWellnessCode
