import { FC, memo, ReactElement } from "react"
import { ReactComponent as LoadingSvg } from "assets/images/loading-icon.svg"
import Button from "components/buttons/Button"
import RightIcon from "assets/images/RightIcon"
import PhoneEmailInput from "components/inputs/PhoneEmailInput"
import useSendWellnessCode from "features/auth/useSendWellnessCode"
import AgreementLinks from "features/login/AgreementLinks"
import BrandHeader from "features/login/BrandHeader"

interface IProps {
  setUserId: (value: string) => void
  setContact: (value: string) => void
  inPopup: boolean
}

const LoginContent: FC<IProps> = memo(
  ({ setUserId, setContact, inPopup }): ReactElement => {
    const {
      handlePhoneEmailInputChange,
      handlePhoneValueSubmit,
      isLoading,
      isButtonDisabled,
      error,
      phoneEmailValue,
    } = useSendWellnessCode({ setUserId, setContact })

    return (
      <>
        <BrandHeader
          isLogoVisible={!inPopup}
          body="Sign in to your account"
          description={
            <>
              <p className="text-base font-normal font-body">
                Enter your mobile or email to continue.
              </p>
              <p className="text-xs font-normal font-body accent-gray-slate -mt-4">
                If you don’t have an account, we’ll create one for you.
              </p>
            </>
          }
        />
        <form className="w-full flex flex-col gap-1.31rem z-10">
          <PhoneEmailInput
            placeholder="Mobile or email"
            error={error}
            value={phoneEmailValue}
            onChange={handlePhoneEmailInputChange}
            inputSize="s"
            className="shadow-sm rounded-lg text-base accent-auroMetalSaurus font-normal"
          />
          <AgreementLinks />
          <Button
            type="submit"
            disabled={isButtonDisabled || isLoading}
            onClick={handlePhoneValueSubmit}
            icon={
              isLoading ? (
                <LoadingSvg />
              ) : (
                <RightIcon disabled={isButtonDisabled || isLoading} />
              )
            }
          >
            {isLoading ? "Please wait..." : "Get started"}
          </Button>
        </form>
      </>
    )
  },
)

export default LoginContent
